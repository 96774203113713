var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticStyle: { "font-weight": "bold", "font-size": "20px" } },
            [_vm._v("1. XÓA DỮ LIỆU LỚN")]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    placeholder: "Chọn xem bảng cần xóa",
                  },
                  on: { change: _vm.handleChangeOption },
                  model: {
                    value: _vm.selectedOption,
                    callback: function ($$v) {
                      _vm.selectedOption = $$v
                    },
                    expression: "selectedOption",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c("el-col", { attrs: { span: 6 } }, [
            _vm.selectedOption !== "" && _vm.dataInfo
              ? _c("div", [
                  _vm._v(
                    "\n          Số lượng row: " +
                      _vm._s(_vm.TABLE_ROWS) +
                      " ;  Dung lượng : " +
                      _vm._s(_vm.DATA_LENGTH) +
                      " MB\n        "
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("label", [_vm._v("Nhập ngày bắt đầu: ")]),
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Ngày", clearable: "" },
                        model: {
                          value: _vm.inputDayDB.inputDayStart,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputDayDB, "inputDayStart", $$v)
                          },
                          expression: "inputDayDB.inputDayStart",
                        },
                      },
                      _vm._l(_vm.days, function (day) {
                        return _c("el-option", {
                          key: day,
                          attrs: { label: day, value: day },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Tháng", clearable: "" },
                        model: {
                          value: _vm.inputDayDB.inputMonthStart,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputDayDB, "inputMonthStart", $$v)
                          },
                          expression: "inputDayDB.inputMonthStart",
                        },
                      },
                      _vm._l(_vm.months, function (month) {
                        return _c("el-option", {
                          key: month,
                          attrs: { label: month, value: month },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Năm", clearable: "" },
                        model: {
                          value: _vm.inputDayDB.inputYearStart,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputDayDB, "inputYearStart", $$v)
                          },
                          expression: "inputDayDB.inputYearStart",
                        },
                      },
                      _vm._l(_vm.years, function (year) {
                        return _c("el-option", {
                          key: year,
                          attrs: { label: year, value: year },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-col",
                { staticStyle: { "margin-left": "50px" }, attrs: { span: 3 } },
                [_c("label", [_vm._v("Nhập ngày kết thúc: ")])]
              ),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Ngày", clearable: "" },
                        model: {
                          value: _vm.inputDayDB.inputDayEnd,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputDayDB, "inputDayEnd", $$v)
                          },
                          expression: "inputDayDB.inputDayEnd",
                        },
                      },
                      _vm._l(_vm.days, function (day) {
                        return _c("el-option", {
                          key: day,
                          attrs: { label: day, value: day },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Tháng", clearable: "" },
                        model: {
                          value: _vm.inputDayDB.inputMonthEnd,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputDayDB, "inputMonthEnd", $$v)
                          },
                          expression: "inputDayDB.inputMonthEnd",
                        },
                      },
                      _vm._l(_vm.months, function (month) {
                        return _c("el-option", {
                          key: month,
                          attrs: { label: month, value: month },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Năm", clearable: "" },
                        model: {
                          value: _vm.inputDayDB.inputYearEnd,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputDayDB, "inputYearEnd", $$v)
                          },
                          expression: "inputDayDB.inputYearEnd",
                        },
                      },
                      _vm._l(_vm.years, function (year) {
                        return _c("el-option", {
                          key: year,
                          attrs: { label: year, value: year },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 5 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: this.isDeleteDataBase === "Ngừng hoạt động",
                          size: "medium",
                          type: "danger",
                          loading: _vm.buttonLoading,
                        },
                        on: { click: _vm.deleteBigData },
                      },
                      [_vm._v(_vm._s(_vm.nameDelete) + "\n          ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("el-row"),
        ],
        1
      ),
      _c("el-row", [
        _vm.responseRowDeleted !== ""
          ? _c("div", { staticStyle: { color: "#0957f4" } }, [
              _vm._v(
                "\n        " + _vm._s(_vm.responseRowDeleted) + "\n      "
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "font-weight": "bold", "font-size": "20px" } },
                [_vm._v("2. CHUYỂN ẢNH TỪ SERVER LÊN S3 AMAZON")]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("label", [_vm._v("Nhập ngày bắt đầu: ")]),
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Ngày", clearable: "" },
                        model: {
                          value: _vm.inputDayS3Amazon.inputDayStart,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputDayS3Amazon, "inputDayStart", $$v)
                          },
                          expression: "inputDayS3Amazon.inputDayStart",
                        },
                      },
                      _vm._l(_vm.days, function (day) {
                        return _c("el-option", {
                          key: day,
                          attrs: { label: day, value: day },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Tháng", clearable: "" },
                        model: {
                          value: _vm.inputDayS3Amazon.inputMonthStart,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.inputDayS3Amazon,
                              "inputMonthStart",
                              $$v
                            )
                          },
                          expression: "inputDayS3Amazon.inputMonthStart",
                        },
                      },
                      _vm._l(_vm.months, function (month) {
                        return _c("el-option", {
                          key: month,
                          attrs: { label: month, value: month },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Năm", clearable: "" },
                        model: {
                          value: _vm.inputDayS3Amazon.inputYearStart,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.inputDayS3Amazon,
                              "inputYearStart",
                              $$v
                            )
                          },
                          expression: "inputDayS3Amazon.inputYearStart",
                        },
                      },
                      _vm._l(_vm.years, function (year) {
                        return _c("el-option", {
                          key: year,
                          attrs: { label: year, value: year },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-col",
                { staticStyle: { "margin-left": "50px" }, attrs: { span: 3 } },
                [_c("label", [_vm._v("Nhập ngày kết thúc: ")])]
              ),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Ngày", clearable: "" },
                        model: {
                          value: _vm.inputDayS3Amazon.inputDayEnd,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputDayS3Amazon, "inputDayEnd", $$v)
                          },
                          expression: "inputDayS3Amazon.inputDayEnd",
                        },
                      },
                      _vm._l(_vm.days, function (day) {
                        return _c("el-option", {
                          key: day,
                          attrs: { label: day, value: day },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Tháng", clearable: "" },
                        model: {
                          value: _vm.inputDayS3Amazon.inputMonthEnd,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputDayS3Amazon, "inputMonthEnd", $$v)
                          },
                          expression: "inputDayS3Amazon.inputMonthEnd",
                        },
                      },
                      _vm._l(_vm.months, function (month) {
                        return _c("el-option", {
                          key: month,
                          attrs: { label: month, value: month },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Năm", clearable: "" },
                        model: {
                          value: _vm.inputDayS3Amazon.inputYearEnd,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputDayS3Amazon, "inputYearEnd", $$v)
                          },
                          expression: "inputDayS3Amazon.inputYearEnd",
                        },
                      },
                      _vm._l(_vm.years, function (year) {
                        return _c("el-option", {
                          key: year,
                          attrs: { label: year, value: year },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c("span", [_vm._v("Chọn trường chuyển dữ liệu: ")]),
                  _c("el-checkbox", {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { disabled: _vm.showCheckBoxS3 },
                    on: { change: _vm.transferDataServerToS3 },
                    model: {
                      value: _vm.showSchoolSelect,
                      callback: function ($$v) {
                        _vm.showSchoolSelect = $$v
                      },
                      expression: "showSchoolSelect",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 10 } }, [
                _c(
                  "span",
                  { staticStyle: { "font-weight": "bold", color: "#F56C6C" } },
                  [_vm._v("Chú ý: ")]
                ),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-weight": "bold",
                      "margin-left": "3px",
                    },
                  },
                  [_vm._v("Chỉ được chuyển trước ngày: ")]
                ),
                _c(
                  "span",
                  { staticStyle: { color: "#409EFF", "font-weight": "bold" } },
                  [_vm._v(_vm._s(this.transferDate))]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "font-weight": "bold",
                    "font-size": "20px",
                    "margin-top": "30px",
                  },
                },
                [_vm._v("3. XÓA ALBUM TRÊN SERVER VÀ S3 AMAZON\n        ")]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("label", [_vm._v("Nhập ngày bắt đầu: ")]),
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Ngày", clearable: "" },
                        model: {
                          value: _vm.inputDayDeleteServerAndS3.inputDayStart,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.inputDayDeleteServerAndS3,
                              "inputDayStart",
                              $$v
                            )
                          },
                          expression: "inputDayDeleteServerAndS3.inputDayStart",
                        },
                      },
                      _vm._l(_vm.days, function (day) {
                        return _c("el-option", {
                          key: day,
                          attrs: { label: day, value: day },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Tháng", clearable: "" },
                        model: {
                          value: _vm.inputDayDeleteServerAndS3.inputMonthStart,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.inputDayDeleteServerAndS3,
                              "inputMonthStart",
                              $$v
                            )
                          },
                          expression:
                            "inputDayDeleteServerAndS3.inputMonthStart",
                        },
                      },
                      _vm._l(_vm.months, function (month) {
                        return _c("el-option", {
                          key: month,
                          attrs: { label: month, value: month },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Năm", clearable: "" },
                        model: {
                          value: _vm.inputDayDeleteServerAndS3.inputYearStart,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.inputDayDeleteServerAndS3,
                              "inputYearStart",
                              $$v
                            )
                          },
                          expression:
                            "inputDayDeleteServerAndS3.inputYearStart",
                        },
                      },
                      _vm._l(_vm.years, function (year) {
                        return _c("el-option", {
                          key: year,
                          attrs: { label: year, value: year },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-col",
                { staticStyle: { "margin-left": "50px" }, attrs: { span: 3 } },
                [_c("label", [_vm._v("Nhập ngày kết thúc: ")])]
              ),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Ngày", clearable: "" },
                        model: {
                          value: _vm.inputDayDeleteServerAndS3.inputDayEnd,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.inputDayDeleteServerAndS3,
                              "inputDayEnd",
                              $$v
                            )
                          },
                          expression: "inputDayDeleteServerAndS3.inputDayEnd",
                        },
                      },
                      _vm._l(_vm.days, function (day) {
                        return _c("el-option", {
                          key: day,
                          attrs: { label: day, value: day },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Tháng", clearable: "" },
                        model: {
                          value: _vm.inputDayDeleteServerAndS3.inputMonthEnd,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.inputDayDeleteServerAndS3,
                              "inputMonthEnd",
                              $$v
                            )
                          },
                          expression: "inputDayDeleteServerAndS3.inputMonthEnd",
                        },
                      },
                      _vm._l(_vm.months, function (month) {
                        return _c("el-option", {
                          key: month,
                          attrs: { label: month, value: month },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Năm", clearable: "" },
                        model: {
                          value: _vm.inputDayDeleteServerAndS3.inputYearEnd,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.inputDayDeleteServerAndS3,
                              "inputYearEnd",
                              $$v
                            )
                          },
                          expression: "inputDayDeleteServerAndS3.inputYearEnd",
                        },
                      },
                      _vm._l(_vm.years, function (year) {
                        return _c("el-option", {
                          key: year,
                          attrs: { label: year, value: year },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c("span", [_vm._v("Chọn trường xóa dữ liệu: ")]),
                  _c("el-checkbox", {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { disabled: _vm.showCheckBoxS3 },
                    on: { change: _vm.deleteDateServerAndS3 },
                    model: {
                      value: _vm.showSchoolSelectDelete,
                      callback: function ($$v) {
                        _vm.showSchoolSelectDelete = $$v
                      },
                      expression: "showSchoolSelectDelete",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 10 } }, [
                _c(
                  "span",
                  { staticStyle: { "font-weight": "bold", color: "#F56C6C" } },
                  [_vm._v("Chú ý: ")]
                ),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-weight": "bold",
                      "margin-left": "3px",
                    },
                  },
                  [_vm._v("Không được xóa sau ngày: ")]
                ),
                _c(
                  "span",
                  { staticStyle: { color: "#409EFF", "font-weight": "bold" } },
                  [_vm._v(_vm._s(this.transferDate))]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("SchoolSelectConfigS3Dialog", {
            attrs: {
              checkSchoolSelect: _vm.checkSchoolSelect,
              isTransfer: _vm.isTransfer,
              dateStart: _vm.dateStart,
              dateEnd: _vm.dateEnd,
            },
            on: {
              close: _vm.handleCloseSchoolSelect,
              childEvent: _vm.handleChildEventTransfer,
            },
          }),
          _c("DeletePictureServerAndS3Dialog", {
            attrs: {
              checkSchoolSelectDelete: _vm.checkSchoolSelectDelete,
              isDeleteAlbum: _vm.isDeleteAlbum,
              dateStart: _vm.dateStart,
              dateEnd: _vm.dateEnd,
            },
            on: {
              close: _vm.handleCloseSchoolSelectDelete,
              childEvent: _vm.handleChildEventDelete,
            },
          }),
          _c("DeleteDataTurnOffAutomaticInvoiceGeneration", {
            attrs: {
              showTurnOffAutomaticInvoiceGenerationDialog:
                _vm.showTurnOffAutomaticInvoiceGenerationDialog,
              dateStart: _vm.dateStart,
              dateEnd: _vm.dateEnd,
            },
            on: { close: _vm.handleCloseTurnOffAutomaticInvoiceGeneration },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }