<template>
  <div>
    <el-row>
      <el-col style="font-weight: bold; font-size: 20px">1. XÓA DỮ LIỆU LỚN</el-col>
    </el-row>
    <el-row :gutter="10">

      <el-col :span="4">
        <el-select
            clearable
            v-model="selectedOption"
            @change="handleChangeOption"
            placeholder="Chọn xem bảng cần xóa">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <div v-if="selectedOption !== '' && dataInfo">
          Số lượng row: {{ TABLE_ROWS }} ; &emsp;Dung lượng : {{ DATA_LENGTH }} MB
        </div>
      </el-col>

    </el-row>
    <div>
    <el-row :gutter="10">
      <el-col :span="3">
        <label>Nhập ngày bắt đầu: </label>
      </el-col>
      <el-col :span="2">
        <div>
          <el-select v-model="inputDayDB.inputDayStart" placeholder="Ngày" clearable>
            <el-option
                v-for="day in days"
                :key="day"
                :label="day"
                :value="day">
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="2">
        <div>
          <el-select v-model="inputDayDB.inputMonthStart" placeholder="Tháng" clearable>
            <el-option
                v-for="month in months"
                :key="month"
                :label="month"
                :value="month">
            </el-option>
          </el-select>
        </div>
      </el-col>

      <el-col :span="2">
        <div>
          <el-select v-model="inputDayDB.inputYearStart" placeholder="Năm" clearable>
            <el-option
                v-for="year in years"
                :key="year"
                :label="year"
                :value="year">
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="3" style="margin-left: 50px">
        <label>Nhập ngày kết thúc: </label>
      </el-col>
      <el-col :span="2">
        <div>
          <el-select v-model="inputDayDB.inputDayEnd" placeholder="Ngày" clearable>
            <el-option
                v-for="day in days"
                :key="day"
                :label="day"
                :value="day">
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="2">
        <div>
          <el-select v-model="inputDayDB.inputMonthEnd" placeholder="Tháng" clearable>
            <el-option
                v-for="month in months"
                :key="month"
                :label="month"
                :value="month">
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="2">
        <div>
          <el-select v-model="inputDayDB.inputYearEnd" placeholder="Năm" clearable>
            <el-option
                v-for="year in years"
                :key="year"
                :label="year"
                :value="year">
            </el-option>
          </el-select>
        </div>
      </el-col>

    </el-row>

    <el-row :gutter="10">


      <el-col :span="5">
        <div>
          <el-button
              :disabled = "this.isDeleteDataBase === 'Ngừng hoạt động'"
              size="medium"
              @click="deleteBigData"
              type="danger"
              :loading="buttonLoading"
          >{{ nameDelete }}
          </el-button>
        </div>
      </el-col>
    </el-row>
    <el-row>
    </el-row>
    </div>
    <el-row>
      <div v-if="responseRowDeleted !==''" style="color: #0957f4">
        {{ responseRowDeleted }}
      </div>
    </el-row>
    <div>
      <el-row>
        <el-col style="font-weight: bold; font-size: 20px">2. CHUYỂN ẢNH TỪ SERVER LÊN S3 AMAZON</el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="3">
          <label>Nhập ngày bắt đầu: </label>
        </el-col>
        <el-col :span="2">
          <div>
            <el-select v-model="inputDayS3Amazon.inputDayStart" placeholder="Ngày" clearable>
              <el-option
                  v-for="day in days"
                  :key="day"
                  :label="day"
                  :value="day">
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="2">
          <div>
            <el-select v-model="inputDayS3Amazon.inputMonthStart" placeholder="Tháng" clearable>
              <el-option
                  v-for="month in months"
                  :key="month"
                  :label="month"
                  :value="month">
              </el-option>
            </el-select>
          </div>
        </el-col>

        <el-col :span="2">
          <div>
            <el-select v-model="inputDayS3Amazon.inputYearStart" placeholder="Năm" clearable>
              <el-option
                  v-for="year in years"
                  :key="year"
                  :label="year"
                  :value="year">
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="3" style="margin-left: 50px">
          <label>Nhập ngày kết thúc: </label>
        </el-col>
        <el-col :span="2">
          <div>
            <el-select v-model="inputDayS3Amazon.inputDayEnd" placeholder="Ngày" clearable>
              <el-option
                  v-for="day in days"
                  :key="day"
                  :label="day"
                  :value="day">
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="2">
          <div>
            <el-select v-model="inputDayS3Amazon.inputMonthEnd" placeholder="Tháng" clearable>
              <el-option
                  v-for="month in months"
                  :key="month"
                  :label="month"
                  :value="month">
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="2">
          <div>
            <el-select v-model="inputDayS3Amazon.inputYearEnd" placeholder="Năm" clearable>
              <el-option
                  v-for="year in years"
                  :key="year"
                  :label="year"
                  :value="year">
              </el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="5">
          <span>Chọn trường chuyển dữ liệu: </span>
          <el-checkbox v-model="showSchoolSelect" @change="transferDataServerToS3" :disabled="showCheckBoxS3"
                       style="margin-left: 10px"></el-checkbox>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="10">
          <span style="font-weight: bold; color: #F56C6C">Chú ý: </span><span
            style="font-weight: bold; margin-left: 3px">Chỉ được chuyển trước ngày: </span>
          <span style="color: #409EFF; font-weight: bold">{{ this.transferDate }}</span>
        </el-col>
      </el-row>
    </div>
    <!--    Xóa dữ liệu ảnh trên s3 và server-->
    <div>
      <el-row>
        <el-col style="font-weight: bold; font-size: 20px; margin-top: 30px">3. XÓA ALBUM TRÊN SERVER VÀ S3 AMAZON
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="3">
          <label>Nhập ngày bắt đầu: </label>
        </el-col>
        <el-col :span="2">
          <div>
            <el-select v-model="inputDayDeleteServerAndS3.inputDayStart" placeholder="Ngày" clearable>
              <el-option
                  v-for="day in days"
                  :key="day"
                  :label="day"
                  :value="day">
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="2">
          <div>
            <el-select v-model="inputDayDeleteServerAndS3.inputMonthStart" placeholder="Tháng" clearable>
              <el-option
                  v-for="month in months"
                  :key="month"
                  :label="month"
                  :value="month">
              </el-option>
            </el-select>
          </div>
        </el-col>

        <el-col :span="2">
          <div>
            <el-select v-model="inputDayDeleteServerAndS3.inputYearStart" placeholder="Năm" clearable>
              <el-option
                  v-for="year in years"
                  :key="year"
                  :label="year"
                  :value="year">
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="3" style="margin-left: 50px">
          <label>Nhập ngày kết thúc: </label>
        </el-col>
        <el-col :span="2">
          <div>
            <el-select v-model="inputDayDeleteServerAndS3.inputDayEnd" placeholder="Ngày" clearable>
              <el-option
                  v-for="day in days"
                  :key="day"
                  :label="day"
                  :value="day">
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="2">
          <div>
            <el-select v-model="inputDayDeleteServerAndS3.inputMonthEnd" placeholder="Tháng" clearable>
              <el-option
                  v-for="month in months"
                  :key="month"
                  :label="month"
                  :value="month">
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="2">
          <div>
            <el-select v-model="inputDayDeleteServerAndS3.inputYearEnd" placeholder="Năm" clearable>
              <el-option
                  v-for="year in years"
                  :key="year"
                  :label="year"
                  :value="year">
              </el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="5">
          <span>Chọn trường xóa dữ liệu: </span>
          <el-checkbox v-model="showSchoolSelectDelete"
                       @change="deleteDateServerAndS3"
                       :disabled="showCheckBoxS3"
                       style="margin-left: 10px"></el-checkbox>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="10">
          <span style="font-weight: bold; color: #F56C6C">Chú ý: </span><span
            style="font-weight: bold; margin-left: 3px">Không được xóa sau ngày: </span>
          <span style="color: #409EFF; font-weight: bold">{{ this.transferDate }}</span>
        </el-col>
      </el-row>
    </div>
    <!--    Xóa dữ liệu ảnh trên s3 và server-->
<!--    <div>-->
<!--      <el-row>-->
<!--        <el-col style="font-weight: bold; font-size: 20px; margin-top: 30px">4. XÓA DỮ LIỆU HÓA ĐƠN VÀ KHOẢN THU CỦA CÁC TRƯỜNG-->
<!--        </el-col>-->
<!--      </el-row>-->
<!--      <el-row :gutter="10">-->
<!--        <el-col :span="3">-->
<!--          <label>Nhập ngày bắt đầu: </label>-->
<!--        </el-col>-->
<!--        <el-col :span="2">-->
<!--          <div>-->
<!--            <el-select v-model="inputDayDeleteTurnOffAutomaticInvoiceGeneration.inputDayStart" placeholder="Ngày" clearable>-->
<!--              <el-option-->
<!--                  v-for="day in days"-->
<!--                  :key="day"-->
<!--                  :label="day"-->
<!--                  :value="day">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </div>-->
<!--        </el-col>-->
<!--        <el-col :span="2">-->
<!--          <div>-->
<!--            <el-select v-model="inputDayDeleteTurnOffAutomaticInvoiceGeneration.inputMonthStart" placeholder="Tháng" clearable>-->
<!--              <el-option-->
<!--                  v-for="month in months"-->
<!--                  :key="month"-->
<!--                  :label="month"-->
<!--                  :value="month">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </div>-->
<!--        </el-col>-->

<!--        <el-col :span="2">-->
<!--          <div>-->
<!--            <el-select v-model="inputDayDeleteTurnOffAutomaticInvoiceGeneration.inputYearStart" placeholder="Năm" clearable>-->
<!--              <el-option-->
<!--                  v-for="year in years"-->
<!--                  :key="year"-->
<!--                  :label="year"-->
<!--                  :value="year">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </div>-->
<!--        </el-col>-->
<!--        <el-col :span="3" style="margin-left: 50px">-->
<!--          <label>Nhập ngày kết thúc: </label>-->
<!--        </el-col>-->
<!--        <el-col :span="2">-->
<!--          <div>-->
<!--            <el-select v-model="inputDayDeleteTurnOffAutomaticInvoiceGeneration.inputDayEnd" placeholder="Ngày" clearable>-->
<!--              <el-option-->
<!--                  v-for="day in days"-->
<!--                  :key="day"-->
<!--                  :label="day"-->
<!--                  :value="day">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </div>-->
<!--        </el-col>-->
<!--        <el-col :span="2">-->
<!--          <div>-->
<!--            <el-select v-model="inputDayDeleteTurnOffAutomaticInvoiceGeneration.inputMonthEnd" placeholder="Tháng" clearable>-->
<!--              <el-option-->
<!--                  v-for="month in months"-->
<!--                  :key="month"-->
<!--                  :label="month"-->
<!--                  :value="month">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </div>-->
<!--        </el-col>-->
<!--        <el-col :span="2">-->
<!--          <div>-->
<!--            <el-select v-model="inputDayDeleteTurnOffAutomaticInvoiceGeneration.inputYearEnd" placeholder="Năm" clearable>-->
<!--              <el-option-->
<!--                  v-for="year in years"-->
<!--                  :key="year"-->
<!--                  :label="year"-->
<!--                  :value="year">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </div>-->
<!--        </el-col>-->
<!--      </el-row>-->
<!--      <el-row :gutter="10">-->
<!--        <el-col :span="5">-->
<!--          <span>Chọn trường xóa dữ liệu: </span>-->
<!--          <el-checkbox v-model="showTurnOffAutomaticInvoiceGenerationDialog"-->
<!--                       @change="deleteInputDayDeleteTurnOffAutomaticInvoiceGeneration"-->
<!--                       style="margin-left: 10px"></el-checkbox>-->
<!--        </el-col>-->
<!--      </el-row>-->
<!--    </div>-->
    <div>
      <SchoolSelectConfigS3Dialog
          :checkSchoolSelect="checkSchoolSelect"
          @close="handleCloseSchoolSelect"
          :isTransfer="isTransfer"
          :dateStart="dateStart"
          :dateEnd="dateEnd"
          @childEvent="handleChildEventTransfer"
      />
      <DeletePictureServerAndS3Dialog
          :checkSchoolSelectDelete="checkSchoolSelectDelete"
          @close="handleCloseSchoolSelectDelete"
          :isDeleteAlbum="isDeleteAlbum"
          :dateStart="dateStart"
          :dateEnd="dateEnd"
          @childEvent="handleChildEventDelete"
      />
      <DeleteDataTurnOffAutomaticInvoiceGeneration
          :showTurnOffAutomaticInvoiceGenerationDialog="showTurnOffAutomaticInvoiceGenerationDialog"
          @close="handleCloseTurnOffAutomaticInvoiceGeneration"
          :dateStart="dateStart"
          :dateEnd="dateEnd"
      />
    </div>
  </div>
</template>

<script>
import OtherService from "@/services/CommonService/OtherService";
import ManageDataService from "@/services/AdminService/ManageDataService";
import NotifyMessage from "@/commonFuncion/NotifyMessage";
import SchoolSelectConfigS3Dialog from "@/views/master/other/SchoolSelectConfigS3Dialog.vue";
import DeletePictureServerAndS3Dialog from "@/views/master/other/DeletePictureServerAndS3Dialog.vue";
import DeleteDataTurnOffAutomaticInvoiceGeneration
  from "@/views/master/other/DeleteDataTurnOffAutomaticInvoiceGeneration.vue";

export default {
  components: {DeleteDataTurnOffAutomaticInvoiceGeneration, DeletePictureServerAndS3Dialog, SchoolSelectConfigS3Dialog},
  props: {
    showMessageRunAction: {
      type: Boolean,
      default: () => false,
    }
  },
  data() {
    return {
      showCheckBoxS3: false,
      showSchoolSelectDelete: false,
      checkSchoolSelectDelete: false,
      showTurnOffAutomaticInvoiceGenerationDialog: false,
      checkSchoolSelect: false,
      showSchoolSelect: false,
      nameDelete: "Tiến hành xóa",
      nameButton: "Tiến hành chuyển",
      buttonLoading: false,
      buttonLoading1: false,
      schoolList: [],
      options: [
        {value: 'ma_evaluate_date', label: 'ma_evaluate_date'},
        {value: 'ma_attendance_kids', label: 'ma_attendance_kids'},
      ],
      selectedOption: '',
      dataInfo: false,
      TABLE_ROWS: 0,
      DATA_LENGTH: 0,
      DATA_INFO_LIST: [],
      responseRowDeleted: "",
      dateStart: "",
      dateEnd: "",
      checked: false,
      inputDayDB: {
        inputDayStart: "",
        inputMonthStart: "",
        inputYearStart: "",
        inputDayEnd: "",
        inputMonthEnd: "",
        inputYearEnd: "",
      },
      inputDayS3Amazon: {
        inputDayStart: "",
        inputMonthStart: "",
        inputYearStart: "",
        inputDayEnd: "",
        inputMonthEnd: "",
        inputYearEnd: "",
      },

      inputDayDeleteServerAndS3: {
        inputDayStart: "",
        inputMonthStart: "",
        inputYearStart: "",
        inputDayEnd: "",
        inputMonthEnd: "",
        inputYearEnd: "",
      },

      inputDayDeleteTurnOffAutomaticInvoiceGeneration: {
        inputDayStart: "",
        inputMonthStart: "",
        inputYearStart: "",
        inputDayEnd: "",
        inputMonthEnd: "",
        inputYearEnd: "",
      },
      days: Array.from({length: 31}, (_, i) => i + 1),
      months: Array.from({length: 12}, (_, i) => i + 1),
      years: [],
      isTransfer: "Ngừng hoạt động",
      isDeleteAlbum: "Ngừng hoạt động",
      isDeleteDataBase: "Ngừng hoạt động",
      transferDate: "",
    };
  },
  created() {
    this.getHistoryActionTransfer("SCANSERVER")
    this.getHistoryActionDelete("DELETEPICS3SV")
    this.getHistoryActionDeleteDataBase("DELETEDATABASE")
  },
  mounted() {
    this.generateYears();
  },
  methods: {
    getHistoryActionDeleteDataBase(codeAction){
      ManageDataService.getHistoryAction(codeAction).then((res) => {
        if (res.data.data.statusAction) {
          this.isDeleteDataBase = "Hoạt động"
        } else {
          this.isDeleteDataBase = "Ngừng hoạt động"
        }
      })
    },
    handleChildEventDelete(data){
      if (data === true){
        this.getMessageAction("DELETEPICS3SV")
      }
    },

    handleChildEventTransfer(data){
      if (data === true){
        this.getMessageAction("SCANSERVER")
      }
    },

    getHistoryActionTransfer(codeAction) {
      ManageDataService.getHistoryAction(codeAction).then((res) => {
        this.transferDate = res.data.data.transferDate
        if (res.data.data.statusAction) {
          this.isTransfer = "Hoạt động"
        } else {
          this.isTransfer = "Ngừng hoạt động"
        }
      })
    },
    getHistoryActionDelete(codeAction) {
      ManageDataService.getHistoryAction(codeAction).then((res) => {
        if (res.data.data.statusAction) {
          this.isDeleteAlbum = "Hoạt động"
        } else {
          this.isDeleteAlbum = "Ngừng hoạt động"
        }
      })
    },
    generateYears() {
      const currentYear = new Date().getFullYear(); // Lấy năm hiện tại
      const startYear = 2020; // Năm bắt đầu
      for (let year = startYear; year <= currentYear; year++) {
        this.years.push(year); // Thêm năm vào mảng
      }
    },
    handleCloseSchoolSelect() {
      this.checkSchoolSelect = false;
      this.showSchoolSelect = false;
    },

    handleCloseSchoolSelectDelete() {
      this.checkSchoolSelectDelete = false;
      this.showSchoolSelectDelete = false;
    },
    getAllSchoolMethod() {
      OtherService.getSchoolAllService()
          .then((resp) => {
            this.schoolList = resp.data.data;
          })
          .catch((err) => {
            this.$message({
              message: err.response.data.message,
              type: "error",
            });
          });
    },
    isValidDate(dateString) {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      return regex.test(dateString);
    },
    handleInput(input) {
      let inputDayStart = "";
      if (parseInt(input.inputDayStart) < 10) {
        inputDayStart = "0" + input.inputDayStart;
      } else {
        inputDayStart = input.inputDayStart;
      }

      let inputMonthStart = "";
      if (parseInt(input.inputMonthStart) < 10) {
        inputMonthStart = "0" + input.inputMonthStart;
      } else {
        inputMonthStart = input.inputMonthStart;
      }

      let inputDayEnd = "";
      if (parseInt(input.inputDayEnd) < 10) {
        inputDayEnd = "0" + input.inputDayEnd;
      } else {
        inputDayEnd = input.inputDayEnd;
      }


      let inputMonthEnd = "";
      if (parseInt(input.inputMonthEnd) < 10) {
        inputMonthEnd = "0" + input.inputMonthEnd;
      } else {
        inputMonthEnd = input.inputMonthEnd;
      }
      const dateStart = input.inputYearStart + '-' + inputMonthStart + '-' + inputDayStart;
      const dateEnd = input.inputYearEnd + '-' + inputMonthEnd + '-' + inputDayEnd;

      this.dateStart = dateStart;
      this.dateEnd = dateEnd;
    },
    handleDate() {

      if (!this.isValidDate(this.dateStart) || !this.isValidDate(this.dateEnd)) {
        NotifyMessage.notifyError("Định dạng ngày không hợp lệ!")
        return false;
      }
      const firstDate = new Date(this.dateStart);
      const secondDate = new Date(this.dateEnd);
      const currentDate = new Date();
      if (secondDate < firstDate) {
        NotifyMessage.notifyError("Ngày kết thúc không được nhỏ hơn ngày bắt đầu!")
        return false;
      }

      if (currentDate < secondDate) {
        NotifyMessage.notifyError("Ngày kết thúc không được lớn hơn ngày hiện tại!")
        return false;
      }
      const differenceInTime = Math.abs(currentDate.getTime() - secondDate.getTime());
      const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

      if (differenceInDays < 366) {
        NotifyMessage.notifyError("Bạn không thể xóa dữ liệu dưới 1 năm!");
        return false;
      }
      return true;
    },

    async handleChangeOption() {
      await ManageDataService.findCapacityFromDB(this.selectedOption)
          .then((resp) => {
            this.DATA_INFO_LIST = resp.data.data[0];
            this.TABLE_ROWS = this.DATA_INFO_LIST[1];
            let number1 = (this.DATA_INFO_LIST[2] + this.DATA_INFO_LIST[3]) / (1024 * 1024);
            this.DATA_LENGTH = Math.round(number1);
          }).catch(err => {
            console.log(err)
          })
      this.dataInfo = true;
    },
    async deleteBigData() {
      if (this.selectedOption === "") {
        NotifyMessage.notifyError("Hãy chọn bảng cần xóa!")
        return;
      }

      await this.handleInput(this.inputDayDB);
      if (!this.handleDate()) {
        return;
      }
      this.$confirm("Bạn có chắc chắn muốn xóa không?", "Cảnh báo!", {
        confirmButtonText: "Có",
        cancelButtonText: "Không",
        type: "warning"
      }).then(async () => {

        this.buttonLoading = true;
        this.responseRowDeleted = "Tải dữ liệu...";
        this.nameDelete = "Đang tiến hành xóa, xin vui lòng đợi";
        await ManageDataService.deleteCapacityFromDB(
            this.dateStart,
            this.dateEnd,
            this.selectedOption)
            .then((resp) => {
              this.responseRowDeleted = resp.data.message;
            }).catch((err) => {
              this.responseRowDeleted = "Có lỗi xảy ra, không thể thực hiện thao tác xóa!"
              console.log(err)
            }).finally(() => {
              this.buttonLoading = false;
              this.checked = false;
              this.nameDelete = "Tiến hành xóa";
            })
      })

    },
    async transferDataServerToS3() {
      await this.handleInput(this.inputDayS3Amazon);
      if (!this.isValidDate(this.dateStart) || !this.isValidDate(this.dateEnd)) {
        NotifyMessage.notifyError("Định dạng ngày không hợp lệ!")
        this.showTurnOffAutomaticInvoiceGenerationDialog = false
      }
      const firstDate = new Date(this.dateStart);
      const secondDate = new Date(this.dateEnd);
      const currentDate = new Date();
      if (secondDate < firstDate) {
        NotifyMessage.notifyError("Ngày kết thúc không được nhỏ hơn ngày bắt đầu!")
        this.showTurnOffAutomaticInvoiceGenerationDialog = false
      }

      if (currentDate < secondDate) {
        NotifyMessage.notifyError("Ngày kết thúc không được lớn hơn ngày hiện tại!")
        this.showTurnOffAutomaticInvoiceGenerationDialog = false
      }

    },

    async deleteDateServerAndS3() {
      await this.handleInput(this.inputDayDeleteServerAndS3);
      if (!this.isValidDate(this.dateStart) || !this.isValidDate(this.dateEnd)) {
        NotifyMessage.notifyError("Định dạng ngày không hợp lệ!")
        this.showSchoolSelectDelete = false
      }
      const firstDate = new Date(this.dateStart);
      const secondDate = new Date(this.dateEnd);
      const currentDate = new Date();
      if (secondDate < firstDate) {
        NotifyMessage.notifyError("Ngày kết thúc không được nhỏ hơn ngày bắt đầu!")
        this.showSchoolSelectDelete = false
      }

      if (currentDate < secondDate) {
        NotifyMessage.notifyError("Ngày kết thúc không được lớn hơn ngày hiện tại!")
        this.showSchoolSelectDelete = false
      }
      if (this.showSchoolSelectDelete === true) {
        this.checkSchoolSelectDelete = true;
      } else {
        this.checkSchoolSelectDelete = false
      }

    },

    getMessageAction(codeAction) {
      let successNotified = false;
      this.showCheckBoxS3 = true;
      this.$notify.info({
        title: "Thông báo",
        message: "Dữ liệu đang được xử lý !",
      });
      setTimeout(() => {
      }, 15000);
      const checkProcess = setInterval(() => {
        if (successNotified) return;

        ManageDataService.getMessageAction(codeAction)
            .then((res) => {
              // chạy neeus k conf processing
              if (res.data.data.message === "processing") {
                this.$notify.info({
                  title: "Thông báo",
                  message: "Dữ liệu đang được xử lý !",
                });
                return;
              }
              if (res.data.data.message === "fail") {
                clearInterval(checkProcess)
                this.$notify.error({
                  title: "Thông báo",
                  message: "Lỗi không xuất được data !",
                });
                return;
              }
              if (res.data.data.message === "complete" && !successNotified) {
                successNotified = true;
                this.$notify.success({
                  title: "Thông báo",
                  message: "Dữ liệu xử lý thành công",
                  duration: 0,
                  onClose: () => {
                    clearInterval(checkProcess);
                  }
                });
                this.showCheckBoxS3 = false;
              }
            })
            .catch((err) => {
              clearInterval(checkProcess);
              return Promise.reject(err);
            });
      }, 10000);
    },

    async deleteInputDayDeleteTurnOffAutomaticInvoiceGeneration(){
      await this.handleInput(this.inputDayDeleteTurnOffAutomaticInvoiceGeneration);
      if (!this.isValidDate(this.dateStart) || !this.isValidDate(this.dateEnd)) {
        NotifyMessage.notifyError("Định dạng ngày không hợp lệ!")
        this.showSchoolSelectDelete = false
      }
      const firstDate = new Date(this.dateStart);
      const secondDate = new Date(this.dateEnd);
      const currentDate = new Date();
      if (secondDate < firstDate) {
        NotifyMessage.notifyError("Ngày kết thúc không được nhỏ hơn ngày bắt đầu!")
        this.showSchoolSelectDelete = false
      }

      if (currentDate < secondDate) {
        NotifyMessage.notifyError("Ngày kết thúc không được lớn hơn ngày hiện tại!")
        this.showSchoolSelectDelete = false
      }
      if (this.showSchoolSelectDelete === true) {
        this.checkSchoolSelectDelete = true;
      } else {
        this.checkSchoolSelectDelete = false
      }
    },

    handleCloseTurnOffAutomaticInvoiceGeneration(){
      this.showTurnOffAutomaticInvoiceGenerationDialog = false
    }


  },
  beforeMount() {
    this.getAllSchoolMethod();
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
}
</style>
