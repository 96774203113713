var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Chọn trường xóa album trên server và s3",
            visible: _vm.checkSchoolSelectDelete,
            width: "1550px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "3vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.checkSchoolSelectDelete = $event
            },
            open: _vm.handleOpen,
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "170px", "margin-left": "10px" },
                  attrs: { placeholder: "Trạng thái kích hoạt" },
                  on: { change: _vm.handleChangeActive },
                  model: {
                    value: _vm.dataSearch.active,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "active", $$v)
                    },
                    expression: "dataSearch.active",
                  },
                },
                _vm._l(_vm.statusAccountList, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { value: item.key, label: item.value },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticClass: "button-left-class",
                  staticStyle: { "margin-left": "5px", width: "160px" },
                  attrs: { placeholder: "Trạng thái xóa" },
                  on: {
                    change: function ($event) {
                      return _vm.searchHeaderMethod()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.deleteStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "deleteStatus", $$v)
                    },
                    expression: "dataSearch.deleteStatus",
                  },
                },
                _vm._l(_vm.deleteList, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { value: item.key, label: item.value },
                  })
                }),
                1
              ),
              _c(
                "el-input",
                {
                  staticStyle: { width: "250px", "margin-left": "5px" },
                  attrs: { placeholder: "Nhập tên trường", clearable: "" },
                  on: {
                    clear: function ($event) {
                      return _vm.searchHeaderMethod()
                    },
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.searchHeaderMethod()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.nameSchool,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "nameSchool", $$v)
                    },
                    expression: "dataSearch.nameSchool",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.searchHeaderMethod()
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              this.isDeleteAlbum === "Ngừng hoạt động"
                ? _c(
                    "span",
                    {
                      staticStyle: { color: "#F56C6C", "margin-left": "20px" },
                    },
                    [_vm._v(" * Chức năng chưa được kích hoạt")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-transfer",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingDataDelete,
                  expression: "loadingDataDelete",
                },
              ],
              staticStyle: { "margin-left": "10px" },
              attrs: {
                "element-loading-text": _vm.$tableLoading,
                titles: ["Tất cả trường", "Trường được xóa"],
                "button-texts": ["", ""],
                data: _vm.dataSource,
              },
              model: {
                value: _vm.dataTarget,
                callback: function ($$v) {
                  _vm.dataTarget = $$v
                },
                expression: "dataTarget",
              },
            },
            [void 0],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "medium",
                    disabled:
                      this.isDeleteAlbum === "Ngừng hoạt động" ||
                      this.dataTarget.length === 0,
                    type: "success",
                    loading: _vm.loadingButton,
                  },
                  on: { click: _vm.deleteAlbumDataServerAndS3 },
                },
                [_vm._v("Xóa dữ liệu\n    ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "110px" },
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialogByButton()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v("Đóng")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }