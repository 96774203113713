import http from "@/http-credential";
// created by Cường
export default new class ManageDataService{
    findCapacityFromDB(tableName){
        return http.get(`/data/controller?tableName=${tableName}`)
    }

    deleteCapacityFromDB(startDate, endDate, tableName){
        return http.delete(`/data/controller?startDate=${startDate}&endDate=${endDate}&tableName=${tableName}`)
    }

    transferDataFromServerToS3(startDate, endDate, listIdSchool){
        return http.get(`/data/controller/transfer?startDate=${startDate}&endDate=${endDate}&listIdSchool=${listIdSchool}`)
    }

    getHistoryAction(codeAction){
        return http.get(`/data/controller/history-action?codeAction=${codeAction}`)
    }
    searchSchoolTransfer(deleteStatus,activated, nameSchool) {
        return http.get(`web/school/searchSchool/transfer/s3?deleteStatus=${deleteStatus}&activated=${activated}&nameSchool=${nameSchool}`);
    }

    deleteAlbumDataServerAndS3(startDate, endDate, listIdSchool){
        return http.delete(`/data/controller/delete/album-server-s3?startDate=${startDate}&endDate=${endDate}&listIdSchool=${listIdSchool}`)
    }

    getMessageAction(codeAction){
        return http.get(`/data/controller/message-action?codeAction=${codeAction}`)
    }
}