<template>
  <el-dialog
      title="Chọn trường chuyển lên S3 Amazon"
      :visible.sync="checkSchoolSelect"
      width="1550px"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      top="3vh"
      @open="handleOpen"
  >
    <div style="margin-bottom: 10px">
      <el-select
          style="width: 170px; margin-left: 10px"
          v-model="dataSearch.active"
          @change="searchHeaderMethod()"
          placeholder="Trạng thái kích hoạt"
          clearable
      >
        <el-option
            v-for="item in statusAccountList"
            :key="item.key"
            :value="item.key"
            :label="item.value"
        ></el-option>
      </el-select>
      <!-- trạng thái xóa -->
      <el-select
          style="margin-left: 5px; width: 160px"
          class="button-left-class"
          v-model="dataSearch.deleteStatus"
          placeholder="Trạng thái xóa"
          @change="searchHeaderMethod()"
          clearable
      >
        <el-option
            v-for="item in deleteList"
            :key="item.key"
            :value="item.key"
            :label="item.value"
        ></el-option>
      </el-select>
      <el-input
          v-model="dataSearch.nameSchool"
          style="width: 250px; margin-left: 5px"
          placeholder="Nhập tên trường"
          @clear="searchHeaderMethod()"
          clearable
          @keyup.enter.native="searchHeaderMethod()"
      >
        <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchHeaderMethod()"
        ></el-button>
      </el-input>
      <span v-if="this.isTransfer === 'Ngừng hoạt động'" style="color: #F56C6C; margin-left: 20px"> * Chức năng chưa được kích hoạt</span>
    </div>
    <el-transfer
        style="margin-left: 10px"
        :element-loading-text="$tableLoading"
        v-loading="loadingDataTransfer"
        v-model="dataTarget"
        :titles="['Tất cả trường', 'Trường được chuyển']"
        :button-texts="['', '']"
        :data="dataSource"
    >
      <template></template>
    </el-transfer>
    <span slot="footer" class="dialog-footer">
      <el-button
          size="medium"
          :disabled="this.isTransfer === 'Ngừng hoạt động' || this.dataTarget.length === 0"
          @click="transferDataServerToS3"
          type="success"
          :loading="loadingButton"
      >Chuyển dữ liệu
      </el-button>
      <el-button type="danger" size="medium" @click="closeDialogByButton()" style="margin-right: 110px">
        <i class="el-icon-circle-close"/>
        <span>Đóng</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import ManageDataService from "@/services/AdminService/ManageDataService";
import NotifyMessage from "@/commonFuncion/NotifyMessage";


export default {
  props: {
    checkSchoolSelect: {
      type: Boolean,
      default: () => false,
    },
    isTransfer: {
      type: String,
      default: () => "Ngừng hoạt động"
    },
    dateStart: {
      type: String,
      default: () => ""
    },
    dateEnd: {
      type: String,
      default: () => ""
    },
  },

  data() {
    return {
      statusAccountList: [
        {
          key: true,
          value: "Đã kích hoạt",
        },
        {
          key: false,
          value: "Chưa kích hoạt",
        },
      ],
      deleteList: [
        {key: true, value: "Chưa xóa"},
        {key: false, value: "Đã xóa"},
      ],
      dataSearch: {
        deleteStatus: true,
        active: true,
        nameSchool: "",
      },
      listSchool: [],
      loadingDataTransfer: false,
      dataSource: [],
      dataTarget: [],
      loadingButton: false
    };
  },
  watch: {
    listSchool: {
      handler() {
        if (this.listSchool.length > 0) {
          setTimeout(() => {
                this.loadingDataTransfer = false
              }
              , 1000
          )
        }
      }
    }
  },
  methods: {
    async searchHeaderMethod() {
      this.loadingDataTransfer = true
      this.dataTarget = []
      await ManageDataService.searchSchoolTransfer(this.dataSearch.deleteStatus, this.dataSearch.active, this.dataSearch.nameSchool).then((res) => {
        this.listSchool = res.data.data.schoolList
      })
      await this.setDataSource()
    },
    handleOpen() {
      console.log(this.$props.dateEnd)
      console.log(this.$props.dateStart)
      this.loadingDataTransfer = false
      this.searchHeaderMethod();
    },
    closeDialog() {
      this.$emit("close");
      this.resetFormData();
      this.handleClear()
    },
    closeDialogByButton() {
      this.$emit("close");
      this.resetFormData();
      this.handleClear()
    },
    handleClear() {
      this.dataSearch.nameSchool = ""
      this.dataSearch.active = true
      this.dataSearch.deleteStatus = true
    },
    resetFormData() {
      setTimeout(() => {
        this.dataSource = [];
        this.dataTarget = [];
      }, 500);
    },

    transferDataServerToS3() {
      this.$confirm("Bạn có chắc chắn thao tác?", "Cảnh báo!", {
        confirmButtonText: "Có",
        cancelButtonText: "Không",
        type: "warning"
      }).then(() => {
        this.$emit('childEvent', true);
        this.$emit("close");
        ManageDataService.transferDataFromServerToS3(this.$props.dateStart, this.$props.dateEnd, this.dataTarget)
            .then(() => {
              console.log("Chuyển thành công")
            }).catch((err) => {
              NotifyMessage.notifyError("Có lỗi xảy ra, không thể thực hiện thao tác!")
              console.log(err)
        }).finally(() => {
          this.loadingButton = false;
          this.closeDialog()
        })
      })
    },
    /**
     * get data source
     */
    setDataSource() {
      const dataResponse = [];
      this.listSchool.forEach((school) => {
        dataResponse.push({
          label: school.id + " -- " + school.schoolName,
          key: school.id,
        });
      });
      this.dataSource = dataResponse;
    },

  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-transfer-panel {
  width: 40%;
}

/deep/ .el-dialog__title {
  font-size: 25px;
  color: #606266;
}

/deep/ .el-dialog__body {
  padding-top: 10px;
}

/deep/ .el-transfer-panel__body {
  height: 300px;
}

/deep/ .el-transfer-panel__list.is-filterable {
  height: 245px;
  padding-top: 0;
}

/deep/ .el-transfer-panel .el-transfer-panel__header {
  background: #78a5e7;
}

/deep/ .el-transfer-panel
.el-transfer-panel__header
.el-checkbox
.el-checkbox__label
span {
  color: white;
}

/deep/ .el-transfer-panel .el-transfer-panel__header .el-checkbox .el-checkbox__label {
  color: white;
}

</style>
